<template>
  <div>
    <!-- 中文 -->
    <div v-if="$store.state.show">
      <h2>Android相关</h2>
      <h3>1. 如何查看包名</h3>
      <p>
        在 <span>app</span> 模块中的 <span>build.gradle</span> 文件的
        <span>android</span>
        中， <span>applicationId</span> 的值即为包名。
      </p>
      <h3>2. 如何查看应用名</h3>
      <p>
        在 <span>app</span> 模块中的 <span>AndroidManifest</span> 文件的
        <span>application</span>
        中， <span>android:label</span> 的值即为包名。
      </p>
      <h3>3. 如何查看SDK的版本号</h3>
      <p>
        在运行日志 <span>Logcat</span> 中搜索 <span>initstatus</span> 即可。
      </p>
      <h3>4. 提示错误“无效的证书”、“无效的包名”、“非法的应用名”</h3>
      <p>
        首先确认SDK的版本号，检查是否为最新版本（<span class="black_span">
          20802以上</span
        >）；其次，因为授权码
        <span>Key</span>
        和包名以及应用名是绑定的，需一一对应；另外还需留意包名是否存在空格。
      </p>
    </div>
    <!-- 英文 -->
    <div v-else>
      <h2>Android related</h2>
      <h3>1. How to check applicationId</h3>
      <p>
        In <span>android</span> block of<span>build.gradle</span> file
        in<span>app</span>module, get the vale of
        <span>applicationId</span>
      </p>
      <h3>2. How to check application name</h3>
      <p>
        In <span>application</span>of<span>AndroidManifest</span> file in
        <span>app</span> module, the value of <span>android:label</span> is
        application name.
      </p>
      <h3>3. How to check SDK version</h3>
      <p>
        Input <span>initstatus</span> in logcat to look over version
        information.
      </p>
      <h3>
        4. Why are the errors “无效证书(Invalid KEY)”, “无效包名(Invalid Bundle
        Identifier)” and “非法的应用名(Invalid Bundle display name)” prompted
      </h3>
      <p>
        Firstly, check whether your SDK version is above<span>20802</span>the
        version below 20802 is deprecated； hen, because the authorization
        code<span>Key</span>
        is bound with the applicationId and application name, it needs
        one-to-one correspondence; Besides, pay attention to whether there are
        spaces in the applicationId.
      </p>
    </div>
  </div>
</template>
<script>
export default {};
</script>
<style lang="scss" scoped>
h2 {
  font-size: 20px;
  font-weight: 500;
  color: #3c4858;
}
h3 {
  font-size: 16px;
  font-weight: 500;
  color: #3c4858;
  margin: 20px 0;
  line-height: 32px;
}
p {
  width: 763px;
  margin: 20px 0;
  font-size: 16px;
  line-height: 32px;
  font-weight: 400;
  color: #3c4858;
}
span {
  color: #e91e63;
}
.black_span {
  color: #3c4858;
  font-size: 16px;
  font-weight: 500;
}
</style>