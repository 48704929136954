var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.$store.state.show)?_c('div',[_c('h2',[_vm._v("Android相关")]),_c('h3',[_vm._v("1. 如何查看包名")]),_vm._m(0),_c('h3',[_vm._v("2. 如何查看应用名")]),_vm._m(1),_c('h3',[_vm._v("3. 如何查看SDK的版本号")]),_vm._m(2),_c('h3',[_vm._v("4. 提示错误“无效的证书”、“无效的包名”、“非法的应用名”")]),_vm._m(3)]):_c('div',[_c('h2',[_vm._v("Android related")]),_c('h3',[_vm._v("1. How to check applicationId")]),_vm._m(4),_c('h3',[_vm._v("2. How to check application name")]),_vm._m(5),_c('h3',[_vm._v("3. How to check SDK version")]),_vm._m(6),_c('h3',[_vm._v(" 4. Why are the errors “无效证书(Invalid KEY)”, “无效包名(Invalid Bundle Identifier)” and “非法的应用名(Invalid Bundle display name)” prompted ")]),_vm._m(7)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('p',[_vm._v(" 在 "),_c('span',[_vm._v("app")]),_vm._v(" 模块中的 "),_c('span',[_vm._v("build.gradle")]),_vm._v(" 文件的 "),_c('span',[_vm._v("android")]),_vm._v(" 中， "),_c('span',[_vm._v("applicationId")]),_vm._v(" 的值即为包名。 ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_vm._v(" 在 "),_c('span',[_vm._v("app")]),_vm._v(" 模块中的 "),_c('span',[_vm._v("AndroidManifest")]),_vm._v(" 文件的 "),_c('span',[_vm._v("application")]),_vm._v(" 中， "),_c('span',[_vm._v("android:label")]),_vm._v(" 的值即为包名。 ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_vm._v(" 在运行日志 "),_c('span',[_vm._v("Logcat")]),_vm._v(" 中搜索 "),_c('span',[_vm._v("initstatus")]),_vm._v(" 即可。 ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_vm._v(" 首先确认SDK的版本号，检查是否为最新版本（"),_c('span',{staticClass:"black_span"},[_vm._v(" 20802以上")]),_vm._v("）；其次，因为授权码 "),_c('span',[_vm._v("Key")]),_vm._v(" 和包名以及应用名是绑定的，需一一对应；另外还需留意包名是否存在空格。 ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_vm._v(" In "),_c('span',[_vm._v("android")]),_vm._v(" block of"),_c('span',[_vm._v("build.gradle")]),_vm._v(" file in"),_c('span',[_vm._v("app")]),_vm._v("module, get the vale of "),_c('span',[_vm._v("applicationId")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_vm._v(" In "),_c('span',[_vm._v("application")]),_vm._v("of"),_c('span',[_vm._v("AndroidManifest")]),_vm._v(" file in "),_c('span',[_vm._v("app")]),_vm._v(" module, the value of "),_c('span',[_vm._v("android:label")]),_vm._v(" is application name. ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_vm._v(" Input "),_c('span',[_vm._v("initstatus")]),_vm._v(" in logcat to look over version information. ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_vm._v(" Firstly, check whether your SDK version is above"),_c('span',[_vm._v("20802")]),_vm._v("the version below 20802 is deprecated； hen, because the authorization code"),_c('span',[_vm._v("Key")]),_vm._v(" is bound with the applicationId and application name, it needs one-to-one correspondence; Besides, pay attention to whether there are spaces in the applicationId. ")])
}]

export { render, staticRenderFns }